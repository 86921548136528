@font-face {
  font-family: 'Pacifico';
  src: url('./pacifico/Pacifico-Regular.eot');
  src: url('./pacifico/Pacifico-Regular.eot?#iefix') format('embedded-opentype'),
    url('./pacifico/Pacifico-Regular.woff2') format('woff2'), url('./pacifico/Pacifico-Regular.woff') format('woff'),
    url('./pacifico/Pacifico-Regular.ttf') format('truetype'),
    url('./pacifico/Pacifico-Regular.svg#Pacifico-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
