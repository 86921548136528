/*******************************************
****************** DIALOG ******************
********************************************/

/* -------------  MAT DIALOG -----------------*/

.mat-dialog-container {
  background-color: var(--color-navy);
  border-radius: 20px !important;
  box-shadow: 0 0 5px 1px var(--color-secondary), 0 0 10px 1px var(--color-secondary);
  border: 1px solid var(--color-secondary);
  min-width: 40vw;
  max-width: 66vw;
  max-height: 100vh !important;
  position: relative;
  font-family: var(--font-text);
  z-index: 1000;
  overflow: hidden !important;

  @include breakpoint(desktop) {
    max-height: 80vh !important;
  }
  @include breakpoint(tabletLandscape) {
    max-height: 70vh !important;
    max-width: 70vw;
    //margin-left: 5vw;
  }

  @include breakpoint(large) {
    min-width: 40vw;
    max-width: 70vw;
    max-height: 80vh !important;
  }

  ::-webkit-scrollbar-track {
    background-color: #47456d;
  }

  .mat-dialog-title {
    position: relative;
    width: 100%;
    min-height: 20px;

    h2 {
      font-family: var(--font-title);
      font-weight: 400;
      font-size: 28px;
      text-align: center;
      line-height: 38px;
      margin: 0 10px 30px 10px;
      text-shadow: var(--color-primary) 0px 0px 5px, var(--color-primary) 0px 0px 10px,
        var(--color-primary) 0px 0px 15px;
      padding: 0 30px;
    }

    .close {
      position: absolute;
      right: -10px;
      top: -10px;
      background-color: transparent;
      box-shadow: none;
      width: auto;
      height: auto;
      font-size: 15px;
    }
  }

  .mat-dialog-content {
    min-height: 30vh;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.5px;
    font-weight: 300;
    margin-right: 7px;

    @include breakpoint(entredeux) {
      font-size: 13px;
      line-height: 24px;
    }
  }

  .mat-dialog-actions {
    margin: 0 10px;
    padding: 0 30px;
    min-height: 70px;
  }
}

/* -------------  DIALOG NOTIFICATION -----------------*/

.dialog-notification {
  .mat-dialog-container {
    overflow: inherit !important;
    z-index: 5000;
  }
}
.dialog-notification {
  &.purple {
    .mat-dialog-container {
      box-shadow: 0 0 5px 1px var(--color-primary), 0 0 10px 1px var(--color-primary);
      border: 1px solid var(--color-primary-bis);
    }
  }
}

/* -------------  BIG DIALOG COACH -----------------*/

.dialog-coach {
  .mat-dialog-container {
    overflow: inherit !important;
  }
}
.dialog-coach {
  &.purple {
    .mat-dialog-container {
      box-shadow: 0 0 5px 1px var(--color-primary), 0 0 10px 1px var(--color-primary);
      border: 1px solid var(--color-primary-bis);
    }
  }
}

/* -------------  SMALL DIALOG COACH -----------------*/

.ngxp__container {
  top: 0;
  transform: none !important;
  margin-top: 30px !important;
  position: relative !important;
  z-index: 0;
  padding: 0 !important;
  outline: none;
  border: 0 !important;

  .ngxp__inner {
    border: 1px solid var(--color-secondary);
    background-color: var(--color-navy);
    box-shadow: 0px 0px 10px 1px var(--color-secondary);
    border-radius: 20px;
    outline: none;
    position: relative;

    &:before {
      content: '';
      transform: rotate(45deg);
      position: absolute;
      z-index: 1;
      top: 68px !important;
      right: -5px !important;
      width: 11.5px;
      height: 11.5px;
      background-color: var(--color-navy);
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      right: -9.5px;
      top: 59px;
      filter: drop-shadow(var(--color-secondary) 0px 0px 5px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 0 15px 15px;
      border-color: transparent transparent transparent var(--color-secondary);
      @media screen and (min-width: 1600px) {
        right: -9.5px;
        top: 59px;
      }
    }
  }

  &.coach-purple {
    .ngxp__inner {
      border: 1px solid var(--color-primary-bis);
      box-shadow: 0px 0px 10px 1px var(--color-primary);
      &:after {
        filter: drop-shadow(var(--color-primary) 0px 0px 5px);
        border-color: transparent transparent transparent var(--color-primary-bis);
      }
    }
  }

  .ngxp__arrow {
    display: none !important;
  }
}
