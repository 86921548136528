/**************************************
         TAILLE DES TROPHEES
**************************************/

/* _________ PUZZLE __________ */

.puzzle {
  .global-puzzle {
    display: flex;
    flex-direction: column;
    .name,
    .new-indicator,
    .container {
      display: block;
    }
  }

  .container {
    position: relative;
    height: 24vw;
    width: 31vw;
    margin: 0 auto;

    @include breakpoint(tabletLandscape) {
      height: 17.5vw;
      width: 23.5vw;
    }

    @include breakpoint(desktop) {
      height: 17.5vw;
      width: 23.5vw;
    }

    @include breakpoint(ipadProPortrait) {
      height: 31vw;
      width: 40vw;
    }

    @include breakpoint(verylarge) {
      height: 18vw;
      width: 23vw;
    }

    @media screen and (min-width: 1820px) {
      height: 17vw;
      width: 21vw;
    }
  }

  .item-puzzle {
    position: absolute;
    img {
      max-height: 180px;
      max-width: 100%;
      @include breakpoint(tabletLandscape) {
        height: 50%;
      }

      &.shiny {
        filter: drop-shadow(0 0 10px var(--color-blue));
      }
    }
  }

  /** item music **/
  .puzzle_1 {
    top: 60%;
    left: 40%;
    transform: translate(-50%, -50%);
    z-index: 35;
    img {
      @include breakpoint(tablet) {
        max-height: 175px;
        max-height: 110px;
      }
      @include breakpoint(desktop) {
        max-height: 175px;
      }
      @include breakpoint(ipadProPortrait) {
        max-height: 200px;
      }
      @include breakpoint(verylarge) {
        max-height: 160px;
      }
      @media screen and (min-width: 1820px) {
        max-height: 188px;
      }
    }

    &.unlock {
      filter: drop-shadow(0 0 10px var(--color-primary));
    }
  }

  /** item corde a sauter **/
  .puzzle_2 {
    top: 0;
    right: 0;
    z-index: 15;
    img {
      @include breakpoint(tablet) {
        max-height: 215px;
        max-height: 123px;
      }
      @include breakpoint(desktop) {
        max-height: 215px;
      }
      @include breakpoint(ipadProPortrait) {
        max-height: 205px;
      }
      @include breakpoint(verylarge) {
        max-height: 160px;
      }
      @media screen and (min-width: 1820px) {
        max-height: 220px;
      }
    }

    &.unlock {
      filter: drop-shadow(0 0 10px var(--color-green));
    }
  }

  /** item gants **/
  .puzzle_3 {
    top: 66%;
    right: 0%;
    z-index: 40;
    img {
      @include breakpoint(tablet) {
        max-height: 108px;
        max-height: 65px;
      }
      @include breakpoint(desktop) {
        max-height: 108px;
      }
      @include breakpoint(ipadProPortrait) {
        max-height: 105px;
      }
      @include breakpoint(verylarge) {
        max-height: 95px;
      }
      @media screen and (min-width: 1820px) {
        max-height: 115px;
        top: 64%;
      }
    }

    &.unlock {
      filter: drop-shadow(0 0 10px var(--color-primary));
    }
  }

  /** item gourde **/
  .puzzle_4 {
    top: 40%;
    left: 0;
    z-index: 20;
    img {
      @include breakpoint(tablet) {
        max-height: 194px;
        max-height: 117px;
      }
      @include breakpoint(desktop) {
        max-height: 194px;
      }
      @include breakpoint(ipadProPortrait) {
        max-height: 200px;
      }
      @include breakpoint(verylarge) {
        max-height: 165px;
      }
      @media screen and (min-width: 1820px) {
        max-height: 190px;
      }
    }

    &.unlock {
      filter: drop-shadow(0 0 10px var(--color-blue));
    }
  }

  /** item haltere **/
  .puzzle_5 {
    top: 0;
    left: 0;
    z-index: 15;
    img {
      @include breakpoint(tablet) {
        max-height: 120px;
        max-height: 75px;
      }
      @include breakpoint(desktop) {
        max-height: 120px;
      }
      @include breakpoint(ipadProPortrait) {
        max-height: 133px;
      }
      @include breakpoint(verylarge) {
        max-height: 100px;
      }
      @media screen and (min-width: 1820px) {
        max-height: 120px;
      }
    }

    &.unlock {
      filter: drop-shadow(0 0 10px var(--color-secondary));
    }
  }
}

/* _________ OTHERS TROPHIES __________ */

.other-trophies {
  .trophy-item {
    margin-bottom: 15px;
    width: 30%;
    flex-basis: 30%;

    .img-trophee {
      width: 100%;
      height: 135px;
      text-align: center;
      position: relative;

      @include breakpoint(ipadProPortrait) {
        height: 180px;
      }

      img {
        max-width: 130px;
        //padding-top: 35px;

        @include breakpoint(ipadProPortrait) {
          max-width: 180px;
          // padding-top: 45px;
        }
        @media screen and (min-width: 1820px) {
          max-width: 155px;
          //padding-top: 45px;
        }
      }

      .unlock {
        &.runner {
          img {
            filter: drop-shadow(0 0 10px var(--color-yellow));
          }
        }
        &.record {
          img {
            filter: drop-shadow(0 0 10px var(--color-blue));
          }
        }
        &.honor {
          img {
            filter: drop-shadow(0 0 10px var(--color-primary));
          }
        }
        &.feat {
          img {
            filter: drop-shadow(0 0 10px var(--color-secondary));
          }
        }
        &.assiduity {
          img {
            filter: drop-shadow(0 0 10px var(--color-blue));
          }
        }
      }
    }
  }
}

.puzzle .name,
.other-trophies .name {
  color: var(--color-white);
  font-family: var(--font-title);
  font-size: 21px;
  padding-bottom: 15px;

  @include breakpoint(tabletLandscape) {
    font-size: 16px;
  }
  @include breakpoint(desktop) {
    font-size: 18px;
  }
  @include breakpoint(large) {
    font-size: 19px;
  }
  @include breakpoint(verylarge) {
    font-size: 21px;
  }
  @media screen and (min-width: 1820px) {
    font-size: 24px;
  }
}

.new-indicator {
  padding-bottom: 15px;
  opacity: 0.5;
  p {
    margin: 0;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-style: italic;
    text-align: center;
    @include breakpoint(large) {
      font-size: 13px;
    }
  }
}

.puzzle-plus,
.other-trophies-plus {
  text-align: center;

  .plus {
    color: var(--color-primary);
    text-shadow: 0 0 4px var(--color-primary);
    font-size: 13px;
    letter-spacing: 0.5px;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    @include breakpoint(desktop) {
      font-size: 15px;
    }

    span {
      color: var(--color-primary);
      border: 2px solid var(--color-primary);
      border-radius: 50%;
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 16px;
      font-size: 12px;
      font-family: var(--font-text);
      text-shadow: 0 0 4px var(--color-primary);
      box-shadow: inset 0 0 4px var(--color-primary), 0 0 4px var(--color-primary);
    }
  }
}

/*******************************
DIALOG NOTIFICATION
********************************/
.dialog-notification {
  idea-trophy-image {
    img {
      max-height: 30vh;
    }
  }
}

/**************************************
        CAROUSEL TROPHEES
**************************************/
.carousel-slide-content {
  overflow-y: auto;
  padding: 0 10px;
  idea-trophy-image {
    height: 20vh !important;
    @include breakpoint(large) {
      height: 24vh !important;
    }
  }
  img {
    padding-top: 0 !important;
    max-height: 120px !important;
    @include breakpoint(large) {
      max-height: 170px !important;
    }
  }
  .slide-content {
    text-align: left;
    font-size: 14px;
    line-height: 21px;
    padding: 0px 40px;

    h2 {
      font-size: 25px;
      font-family: var(--font-title);
      font-weight: normal;
      text-align: center;
      margin-bottom: 30px;
    }

    .label-unlock {
      text-transform: uppercase;
      color: var(--color-secondary);
      line-height: 40px;
    }

    .description {
      margin-top: 5px;
      margin-bottom: 10px;
      p {
        font-size: 14px;
        line-height: 25px;
      }
    }
    .objectifs {
      margin-bottom: 20px;
    }
    ul {
      li {
        list-style-type: none;
        &:before {
          content: '\e90b';
          font-family: var(--font-icon);
          font-size: 14px;
          line-height: 21px;
          color: var(--color-disabled);
          padding-right: 10px;
        }
      }
    }
    &.unlock {
      h2 {
        text-shadow: 0 0 5px var(--color-secondary);
      }

      ul {
        li {
          &:before {
            color: var(--color-green);
          }
        }
      }
    }
  }
  .unlock {
    &.puzzle_1 {
      img {
        filter: drop-shadow(0 0 10px var(--color-primary));
      }
    }
    &.puzzle_2 {
      img {
        filter: drop-shadow(0 0 10px var(--color-green));
      }
    }
    &.puzzle_3 {
      img {
        filter: drop-shadow(0 0 10px var(--color-primary));
      }
    }
    &.puzzle_4 {
      img {
        filter: drop-shadow(0 0 10px var(--color-blue));
      }
    }
    &.puzzle_5 {
      img {
        filter: drop-shadow(0 0 10px var(--color-secondary));
      }
    }
  }

  .puzzle_3 img,
  .puzzle_5 img {
    height: 70%;
  }
}

.carousel {
  .carousel-indicators {
    bottom: -5px !important;
  }
}

.global-slide {
  padding: 0 20px;
}

.carousel > button:last-of-type {
  right: 0 !important;
  &:before {
    content: '\e912';
    font-family: var(--font-icon);
    font-size: 40px;
    color: var(--color-disabled);
  }
  .mat-icon {
    display: none;
  }
}

.carousel > button:first-of-type {
  left: 0 !important;
  &:before {
    content: '\e913';
    font-family: var(--font-icon);
    font-size: 40px;
    color: var(--color-disabled);
  }
  .mat-icon {
    display: none;
  }
}
