// Responsive breakpoints
@mixin breakpoint($point) {
  @if $point == verylarge {
    @media all and (min-width: 1366px) {
      @content;
    }
  } @else if $point == large {
    @media all and (min-width: 1200px) {
      @content;
    }
    /* 1193px */
  } @else if $point == desktop {
    @media all and (min-width: 1025px) {
      @content;
    }
  } @else if $point == entreDeux {
    @media all and (min-width: 900px) {
      @content;
    }
    /*804px*/
  } @else if $point == tablet {
    @media all and (min-width: 768px) {
      @content;
    }
  } @else if $point == phablet {
    @media all and (min-width: 600px) {
      @content;
    }
  } @else if $point == smartphone {
    @media all and (min-width: 480px) {
      @content;
    }
  } @else if $point == smallonly {
    @media all and (max-width: 767px) {
      @content;
    }
  }

  /**** Spécifique ****/
  @else if $point == treizePouces {
    @media all and (min-width: 804px) and (min-height: 800px) and (max-height: 870px) {
      @content;
    }
  } @else if $point == mobileLandscape {
    @media screen and (max-height: 480px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == tabletLandscape {
    @media screen and (min-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == ipadProPortrait {
    @media all and (min-width: 1024px) and (min-height: 1025px) and (max-height: 1369px) {
      @content;
    }
  } @else if $point == ipadProLandscape {
    @media all and (min-width: 1366px) and (max-height: 1024px) {
      @content;
    }
  }
}
