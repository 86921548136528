@import './fonts/icomoon';
@import './fonts/pacifico';
@import './fonts/poppins';

$font-title: 'Pacifico', sans-serif;
$font-text: 'Poppins', sans-serif;

:root {
  --font-title: 'Pacifico', sans-serif;
  --font-text: 'Poppins', sans-serif;
  --font-icon: 'icomoon';
}

html {
  font-size: 100%;
}

h3,
h4,
h5,
h6 {
  font-family: var(--font-text);
}
