/*****   MAT CHECKBOX   *****/
.mat-checkbox {
  .mat-checkbox-frame {
    border-color: var(--color-disabled);
    border-width: 1px;
  }
  .mat-checkbox-ripple {
    display: none;
  }

  &:hover {
    .mat-checkbox-frame {
      border-color: var(--color-primary-bis);
      box-shadow: inset 0 0 4px var(--color-primary), 0 0 4px var(--color-primary), 0 0 4px var(--color-primary);
    }
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: transparent;
    }
    .mat-checkbox-checkmark {
      fill: var(--color-primary-bis);
      filter: drop-shadow(0 0 4px var(--color-primary));
    }
    .mat-checkbox-mixedmark {
      background-color: var(--color-primary-bis);
      filter: drop-shadow(0 0 4px var(--color-primary));
    }
    .mat-checkbox-checkmark-path {
      stroke: var(--color-primary-bis) !important;
      filter: drop-shadow(0 0 4px var(--color-primary));
    }
    .mat-checkbox-frame {
      border-color: var(--color-primary-bis);
      box-shadow: inset 0 0 4px var(--color-primary), 0 0 4px var(--color-primary), 0 0 4px var(--color-primary);
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: var(--font-text);
  mat-label {
    padding-bottom: 7px;
    font-size: 14px;
  }
  .mat-form-field-appearance-outline {
    .mat-form-field-flex {
      padding: 0 15px;

      .mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          border-radius: 0;
          border-color: var(--color-secondary);
          border-width: 2px;
        }
      }

      .mat-form-field-infix {
        padding: 15px 0 15px 0;
        border-top: 0.4em solid transparent;
        font-size: 14px;

        .mat-select {
          .mat-select-arrow-wrapper {
            padding-left: 25px;
            .mat-select-arrow {
              border-left: 0;
              border-right: 0;
              border-top: 0;
              &:before {
                content: '\e910';
                font-family: 'icomoon';
                position: absolute;
                width: 20px;
                height: 20px;
                right: -6px;
                top: 3px;
                color: var(--color-white);
                display: block;
              }
            }
          }
          .mat-select-panel-wrap {
            .mat-select-panel {
              background-color: #250e3a !important;
              .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
                color: var(--color-secondary) !important;
              }
              .mat-primary .mat-pseudo-checkbox-checked,
              .mat-primary .mat-pseudo-checkbox-indeterminate {
                background: var(--color-secondary);
              }
            }
          }
        }
      }
    }
  }

  .mat-form-field {
    &.mat-form-field-disabled {
      .mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
          border-color: var(--color-disabled);
        }
      }
      .mat-form-field-flex {
        > .mat-form-field-outline {
          background-color: rgba(154, 104, 152, 0.2);
        }
      }
    }
  }
}

.mat-select-panel {
  background-color: #250e3a !important;
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background-color: var(--color-secondary);
  }
  .mat-option {
    .mat-option-text {
      color: var(--color-secondary);
    }
    &.filter-option {
      .mat-pseudo-checkbox {
        opacity: 0;
      }
    }
  }
}
