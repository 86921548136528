@import './styles/settings/reset';
@import './styles/settings/palettes';
@import './styles/settings/material';
@import './styles/settings/fonts';
@import './styles/settings/variables';
@import './styles/settings/mixins';

@import './styles/components/badges';
@import './styles/components/buttons';
@import './styles/components/ckeditor';
@import './styles/components/links';
@import './styles/components/tables';
@import './styles/components/toggle';
@import './styles/components/tabs';
@import './styles/components/forms';
@import './styles/components/paginator';
@import './styles/components/general';
@import './styles/components/dialog';
@import './styles/components/trophies';
@import './styles/components/overlays';

// Lib external
@import '~ngx-toastr/toastr';

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body {
  background-color: #210f3f;
  color: var(--color-white);
  font-family: var(--font-text);
  #layout-container {
    min-height: calc(100vh - 64px - 50px - 80px);
  }

  &.idea-full-width {
    #layout-container {
      width: calc(100% - 30px);
      margin-left: 30px;
    }
  }
  &.idea-one-page {
    #layout-container {
      height: calc(100vh - 64px - 50px - 80px);
    }
  }
}
