table {
  &.mat-table,
  &.table-stats {
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--color-white);
    font-family: var(--font-text);
    thead {
      th {
        border-bottom: 1px solid var(--color-white);
        color: var(--color-white);
        font-size: 15px;
        letter-spacing: 1px;
        &.center {
          text-align: center;
          .mat-sort-header-container {
            justify-content: center;
          }
        }
      }
    }
    tbody {
      border-top: 1px solid var(--color-white);
      tr {
        height: 40px;
      }
      td {
        border: 0;
        font-size: 14px;
        font-weight: 300;
        &.center {
          text-align: center;
        }
      }
    }
  }
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 20px;
}

table {
  &.table-stats {
    th {
      padding: 10px;
      &.header-main {
        border-bottom: none;
      }
    }
    .header-second {
      th {
        font-weight: normal;
      }
    }
  }
}
