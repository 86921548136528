/**************************  LAYOUT  ****************************/

.layout-content {
  &.layout-full {
    width: 100%;
    margin: auto;
  }
  &.layout-medium {
    width: 70%;
    margin: auto;
  }
  &.layout-small {
    width: 55%;
    margin: auto;
  }
  &.layout-verysmall {
    width: 40%;
    margin: auto;
  }
}

/***************************  SCROLLBAR  ************************/

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background-color: var(--color-navy);
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary);
  outline: 1px solid var(--color-secondary);
  //box-shadow: 0px 0px 6px 4px var(--color-secondary);
}

/***************************  NOTIFICATIONS  ************************/

.toast-container {
  position: relative;

  .toast-title {
    font-family: var(--font-text) !important;
    font-weight: 600 !important;
    letter-spacing: 1.2px !important;
    padding: 5px 0;
    margin-left: 10px;
  }

  .toast-message {
    font-size: 12px;
    letter-spacing: 0.7px;
    margin-left: 10px;
  }

  .ngx-toastr {
    box-shadow: none !important;
    background-image: none;
    &:before {
      font-family: var(--font-icon);
      color: var(--color-white);
      background-color: rgba(255, 255, 255, 0.3);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 15px;
      text-align: center;
      line-height: 30px;
      font-weight: bold;
      transform: translateY(-50%);
    }
  }

  .toast-success {
    background-color: var(--color-secondary) !important;
    &:before {
      content: '\e90b';
    }
  }

  .toast-info {
    background-color: var(--color-blue) !important;
    &:before {
      content: '\e903';
      font-weight: 400;
    }
  }

  .toast-error {
    background-color: var(--color-primary-bis) !important;
    &:before {
      content: '\e914';
    }
  }
}

.text-center {
  text-align: center;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-75 {
  margin-bottom: 75px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mt-80 {
  margin-top: 80px;
}

/*********** SPINNER *********/

.mat-progress-spinner {
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}
