:root {
  --navbar-height: 64px;
  --footer-height: 50px;

  --margin-large: 50px;
  --margin-medium: 25px;
  --margin-small: 10px;

  --font-size-tablet: 12px;
  --font-size-entreDeux: 12px;
  --font-size-desktop: 12px;
  --font-size-large: 13px;
  --font-size-verylarge: 14px;
  --font-size-wide: 15px;
}
