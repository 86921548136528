.mat-paginator {
  background: transparent;
  font-family: var(--font-text);
  color: var(--color-white);
  opacity: 1;
  .mat-paginator-icon {
    width: 25px;
  }
  .mat-paginator-navigation-previous,
  .mat-paginator-navigation-next {
    color: var(--color-primary-bis);
    opacity: 1;
    svg {
      filter: drop-shadow(0 0 4px var(--color-primary));
    }
    &.mat-button-disabled {
      color: var(--color-disabled);
      opacity: 1;
      svg {
        filter: none;
      }
    }
  }
}
