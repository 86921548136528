@font-face {
  font-family: 'icomoon';
  src: url('./icomoon/icomoon.eot?yepg8j');
  src: url('./icomoon/icomoon.eot?yepg8j#iefix') format('embedded-opentype'),
    url('./icomoon/icomoon.woff2?yepg8j') format('woff2'), url('./icomoon/icomoon.ttf?yepg8j') format('truetype'),
    url('./icomoon/icomoon.woff?yepg8j') format('woff'), url('./icomoon/icomoon.svg?yepg8j#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cancel-circle:before {
  content: '\e91d';
}
.icon-archive:before {
  content: '\e91b';
}
.icon-paperclip:before {
  content: '\e901';
}
.icon-edit:before {
  content: '\e90e';
}
.icon-delete:before {
  content: '\e90f';
}
.icon-lock-open-outline:before {
  content: '\e91a';
}
.icon-unlock:before {
  content: '\e917';
}
.icon-plus:before {
  content: '\e916';
}
.icon-alert:before {
  content: '\e907';
}
.icon-hourglass:before {
  content: '\e91c';
}
.icon-lock_open:before {
  content: '\e919';
}
.icon-file_download:before {
  content: '\e905';
}
.icon-clear:before {
  content: '\e914';
}
.icon-refresh:before {
  content: '\e90a';
}
.icon-check:before {
  content: '\e90b';
}
.icon-lock-open:before {
  content: '\e918';
}
.icon-info:before {
  content: '\e903';
}
.icon-settings:before {
  content: '\e90d';
}
.icon-download:before {
  content: '\e904';
}
.icon-close:before {
  content: '\e915';
}
.icon-cheveron-down:before {
  content: '\e906';
}
.icon-check1:before {
  content: '\e90c';
}
.icon-lock-card:before {
  content: '\e902';
}
.icon-profile:before {
  content: '\e900';
}
.icon-badge:before {
  content: '\e908';
}
.icon-lock:before {
  content: '\e909';
}
.icon-angle-down:before {
  content: '\e910';
}
.icon-angle-up:before {
  content: '\e911';
}
.icon-angle-right:before {
  content: '\e912';
}
.icon-angle-left:before {
  content: '\e913';
}
