.mat-slide-toggle {
  .mat-slide-toggle-bar {
    background-color: transparent;
    border: 1px solid #8f588d;
    height: 22px;
    width: 42px;
    border-radius: 30px;
  }

  .mat-slide-toggle-thumb-container {
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
  }

  .mat-slide-toggle-thumb {
    width: 16px;
    height: 16px;
    border: 1px solid #8f588d;
    background-color: transparent;
    box-shadow: none;
  }

  .mat-ripple-element {
    display: none !important;
  }

  &.mat-checked {
    &.mat-primary {
      .mat-slide-toggle-bar {
        border: 1px solid #de5bbc;
        background-color: transparent !important;
        box-shadow: inset 0 0 4px var(--color-primary-400), 0 0 4px var(--color-primary-400);
      }
      .mat-slide-toggle-thumb-container {
        transform: translate3d(19px, 0, 0) !important;
      }
      .mat-slide-toggle-thumb {
        background-color: transparent !important;
        border: 1px solid #de5bbc;
        box-shadow: inset 0 0 4px var(--color-primary-400), 0 0 4px var(--color-primary-400);
      }
    }

    &.mat-accent {
      .mat-slide-toggle-bar {
        border: 1px solid var(--color-secondary-400);
        background-color: transparent !important;
        box-shadow: inset 0 0 4px var(--color-secondary-400), 0 0 4px var(--color-secondary-400);
      }
      .mat-slide-toggle-thumb-container {
        transform: translate3d(19px, 0, 0) !important;
      }
      .mat-slide-toggle-thumb {
        background-color: transparent !important;
        border: 1px solid var(--color-secondary-400);
        box-shadow: inset 0 0 4px var(--color-secondary-400), 0 0 4px var(--color-secondary-400);
      }
    }
  }
}
