/*******************************************
********************************************
              BADGE
********************************************
********************************************/
.badge {
  border-radius: 30px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: $font-text;
  padding: 12px 18px;
  box-shadow: none !important;

  @include breakpoint(entredeux) {
    font-size: 14px;
    line-height: 21px;
    padding: 9px 20px;
  }

  &.primary {
    background-color: var(--color-primary-900);
    color: var(--color-white);
  }
  &.accent {
    background-color: var(--color-secondary-900);
    color: var(--color-white);
  }
  &.warn {
    background-color: #df0000;
    color: var(--color-white);
  }
  &.white {
    background-color: var(--color-white);
    color: var(--color-black);
  }
}
