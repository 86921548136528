:root {
  // Primary colors
  --color-primary-50: #f8e0fe;
  --color-primary-100: #ecb3fd;
  --color-primary-200: #e080fc;
  --color-primary-300: #d44dfb;
  --color-primary-400: #ca26fa;
  --color-primary-500: #c100f9;
  --color-primary-600: #bb00f8;
  --color-primary-700: #b300f7;
  --color-primary-800: #ab00f6;
  --color-primary-900: #9e00f5;
  --color-primary-A100: #ffffff;
  --color-primary-A200: #f6e8ff;
  --color-primary-A400: #e1b5ff;
  --color-primary-A700: #d79cff;
  --color-primary-contrast-50: #000000;
  --color-primary-contrast-100: #000000;
  --color-primary-contrast-200: #000000;
  --color-primary-contrast-300: #000000;
  --color-primary-contrast-400: #ffffff;
  --color-primary-contrast-500: #ffffff;
  --color-primary-contrast-600: #ffffff;
  --color-primary-contrast-700: #ffffff;
  --color-primary-contrast-800: #ffffff;
  --color-primary-contrast-900: #ffffff;
  --color-primary-contrast-A100: #000000;
  --color-primary-contrast-A200: #000000;
  --color-primary-contrast-A400: #000000;
  --color-primary-contrast-A700: #000000;

  // Secondary colors
  --color-secondary-50: #e5fbf7;
  --color-secondary-100: #bef5ec;
  --color-secondary-200: #93efdf;
  --color-secondary-300: #68e8d2;
  --color-secondary-400: #47e3c9;
  --color-secondary-500: #27debf;
  --color-secondary-600: #23dab9;
  --color-secondary-700: #1dd5b1;
  --color-secondary-800: #17d1a9;
  --color-secondary-900: #0ec89b;
  --color-secondary-A100: #f5fffc;
  --color-secondary-A200: #c2ffef;
  --color-secondary-A400: #8fffe1;
  --color-secondary-A700: #75ffda;
  --color-secondary-contrast-50: #000000;
  --color-secondary-contrast-100: #000000;
  --color-secondary-contrast-200: #000000;
  --color-secondary-contrast-300: #000000;
  --color-secondary-contrast-400: #000000;
  --color-secondary-contrast-500: #000000;
  --color-secondary-contrast-600: #000000;
  --color-secondary-contrast-700: #000000;
  --color-secondary-contrast-800: #000000;
  --color-secondary-contrast-900: #000000;
  --color-secondary-contrast-A100: #000000;
  --color-secondary-contrast-A200: #000000;
  --color-secondary-contrast-A400: #000000;
  --color-secondary-contrast-A700: #000000;

  // Other colors
  --color-primary: var(--color-primary-500);
  --color-primary-lighten: var(--color-primary-300);
  --color-primary-dark: #020029;
  --color-secondary: var(--color-secondary-500);
  --color-secondary-lighten: var(--color-secondary-300);
  --color-white: #ffffff;
  --color-grey: #f3f3f3;
  --color-grey-darken: #c7d2db;
  --color-black: #252525;
  --color-danger: #ff0000;
  --color-danger-lighten: #f54c46;
  --color-success: #4fc553;
  --color-disabled: #9a6898;
  --color-primary-bis: #de5bbc;
  --color-blue: #46ecff;
  --color-yellow: #efd003;
  --color-green: #6dff26;
  --color-navy: #020029;

  // Darken
  --color-darken-pink: darken(var(--color-primary-bis, 10%));
  $color-darken-blue: darken(#46ecff, 10%);
  --color-darken-blue: $color-darken-blue;
  --color-darken-yellow: darken(var(--color-yellow, 10%));
  --color-darken-green: darken(var(--color-green, 10%));

  // Shadow
  --text-shadow-primary: var(--color-primary) 0px 0px 5px, var(--color-primary) 0px 0px 10px,
    var(--color-primary) 0px 0px 15px;
}

// http://mcg.mbitson.com/#!?mcgpalette0=%23eb9844
$mat-custom-primary: (
  50: var(--color-primary-50),
  100: var(--color-primary-100),
  200: var(--color-primary-200),
  300: var(--color-primary-300),
  400: var(--color-primary-400),
  500: var(--color-primary-500),
  600: var(--color-primary-600),
  700: var(--color-primary-700),
  800: var(--color-primary-800),
  900: var(--color-primary-900),
  A100: var(--color-primary-A100),
  A200: var(--color-primary-A200),
  A400: var(--color-primary-A400),
  A700: var(--color-primary-A700),
  contrast: (
    50: var(--color-primary-contrast-50),
    100: var(--color-primary-contrast-100),
    200: var(--color-primary-contrast-200),
    300: var(--color-primary-contrast-300),
    400: var(--color-primary-contrast-400),
    500: var(--color-primary-contrast-500),
    600: var(--color-primary-contrast-600),
    700: var(--color-primary-contrast-700),
    800: var(--color-primary-contrast-800),
    900: var(--color-primary-contrast-900),
    A100: var(--color-primary-contrast-A100),
    A200: var(--color-primary-contrast-A200),
    A400: var(--color-primary-contrast-A400),
    A700: var(--color-primary-contrast-A700),
  ),
);

$mat-custom-accent: (
  50: var(--color-secondary-50),
  100: var(--color-secondary-100),
  200: var(--color-secondary-200),
  300: var(--color-secondary-300),
  400: var(--color-secondary-400),
  500: var(--color-secondary-500),
  600: var(--color-secondary-600),
  700: var(--color-secondary-700),
  800: var(--color-secondary-800),
  900: var(--color-secondary-900),
  A100: var(--color-secondary-A100),
  A200: var(--color-secondary-A200),
  A400: var(--color-secondary-A400),
  A700: var(--color-secondary-A700),
  contrast: (
    50: var(--color-secondary-contrast-50),
    100: var(--color-secondary-contrast-100),
    200: var(--color-secondary-contrast-200),
    300: var(--color-secondary-contrast-300),
    400: var(--color-secondary-contrast-400),
    500: var(--color-secondary-contrast-500),
    600: var(--color-secondary-contrast-600),
    700: var(--color-secondary-contrast-700),
    800: var(--color-secondary-contrast-800),
    900: var(--color-secondary-contrast-900),
    A100: var(--color-secondary-contrast-A100),
    A200: var(--color-secondary-contrast-A200),
    A400: var(--color-secondary-contrast-A400),
    A700: var(--color-secondary-contrast-A700),
  ),
);
