a {
  color: var(--color-primary);
  text-decoration: none;
  &.active {
    color: var(--color-secondary);
  }
}

.mat-menu-panel {
  background-color: var(--color-secondary) !important;
  min-width: 140px;
  list-style-type: none;
  position: absolute;
  padding: 10px 20px;
  right: 10px;
  top: 9px;
  border-radius: 0 !important;
  .mat-menu-content {
    padding: 0 !important;
    a,
    button {
      font-family: var(--font-text);
      text-transform: uppercase;
      font-size: 14px;
      line-height: 25px;
      height: auto;
      font-weight: 300;
      letter-spacing: 3px;
      position: relative;
      text-align: center;
      font-weight: 300;
      padding: 7px 0;
      display: block;
      position: relative;

      &:before {
        content: '';
        height: 2px;
        width: 35px;
        background-color: transparent;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%);
        box-shadow: none;
        z-index: 1000;
      }

      &:hover {
        cursor: pointer;
        &:before {
          background-color: var(--color-white);
        }
      }
    }
  }
}
