@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-ExtraBold.eot');
  src: url('./poppins/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-ExtraBold.woff2') format('woff2'), url('./poppins/Poppins-ExtraBold.woff') format('woff'),
    url('./poppins/Poppins-ExtraBold.ttf') format('truetype'),
    url('./poppins/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-ExtraBoldItalic.eot');
  src: url('./poppins/Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
    url('./poppins/Poppins-ExtraBoldItalic.woff') format('woff'),
    url('./poppins/Poppins-ExtraBoldItalic.ttf') format('truetype'),
    url('./poppins/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic') format('svg');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-BoldItalic.eot');
  src: url('./poppins/Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-BoldItalic.woff2') format('woff2'), url('./poppins/Poppins-BoldItalic.woff') format('woff'),
    url('./poppins/Poppins-BoldItalic.ttf') format('truetype'),
    url('./poppins/Poppins-BoldItalic.svg#Poppins-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-Bold.eot');
  src: url('./poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-Bold.woff2') format('woff2'), url('./poppins/Poppins-Bold.woff') format('woff'),
    url('./poppins/Poppins-Bold.ttf') format('truetype'), url('./poppins/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-SemiBold.eot');
  src: url('./poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-SemiBold.woff2') format('woff2'), url('./poppins/Poppins-SemiBold.woff') format('woff'),
    url('./poppins/Poppins-SemiBold.ttf') format('truetype'),
    url('./poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-SemiBoldItalic.eot');
  src: url('./poppins/Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
    url('./poppins/Poppins-SemiBoldItalic.woff') format('woff'),
    url('./poppins/Poppins-SemiBoldItalic.ttf') format('truetype'),
    url('./poppins/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-Medium.eot');
  src: url('./poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-Medium.woff2') format('woff2'), url('./poppins/Poppins-Medium.woff') format('woff'),
    url('./poppins/Poppins-Medium.ttf') format('truetype'),
    url('./poppins/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-MediumItalic.eot');
  src: url('./poppins/Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-MediumItalic.woff2') format('woff2'),
    url('./poppins/Poppins-MediumItalic.woff') format('woff'),
    url('./poppins/Poppins-MediumItalic.ttf') format('truetype'),
    url('./poppins/Poppins-MediumItalic.svg#Poppins-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-Regular.eot');
  src: url('./poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-Regular.woff2') format('woff2'), url('./poppins/Poppins-Regular.woff') format('woff'),
    url('./poppins/Poppins-Regular.ttf') format('truetype'),
    url('./poppins/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-Italic.eot');
  src: url('./poppins/Poppins-Italic.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-Italic.woff2') format('woff2'), url('./poppins/Poppins-Italic.woff') format('woff'),
    url('./poppins/Poppins-Italic.ttf') format('truetype'),
    url('./poppins/Poppins-Italic.svg#Poppins-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-Light.eot');
  src: url('./poppins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-Light.woff2') format('woff2'), url('./poppins/Poppins-Light.woff') format('woff'),
    url('./poppins/Poppins-Light.ttf') format('truetype'),
    url('./poppins/Poppins-Light.svg#Poppins-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./poppins/Poppins-LightItalic.eot');
  src: url('./poppins/Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./poppins/Poppins-LightItalic.woff2') format('woff2'), url('./poppins/Poppins-LightItalic.woff') format('woff'),
    url('./poppins/Poppins-LightItalic.ttf') format('truetype'),
    url('./poppins/Poppins-LightItalic.svg#Poppins-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
