/*******************************************
********************************************
              RAISED BUTTON
********************************************
********************************************/
button.mat-raised-button,
a.mat-raised-button {
  border-radius: 30px;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: $font-text;
  padding: 0 20px;
  line-height: 25px;
  box-shadow: none !important;
  margin: 0 10px;
  color: var(--color-white) !important;
  &.mat-primary {
    &:hover {
      background-color: var(--color-primary-900);
    }
  }
  &.mat-accent {
    &:hover {
      background-color: var(--color-secondary-900);
    }
  }
  &.mat-warn {
    &:hover {
      background-color: #df0000;
    }
  }
  &.mat-button-disabled {
    background-color: var(--color-disabled) !important;
    color: #ecceeb !important;
  }
  &.choice-btn {
    text-transform: inherit;
    border-radius: 15px;
    font-size: 13px !important;
    font-weight: 500;
    padding: 8px 18px;
    line-height: 18px;
    letter-spacing: 0.5px;
    white-space: normal;
    @include breakpoint(entredeux) {
      font-size: 14px !important;
      line-height: 22px;
      padding: 10px 25px;
    }
  }

  @include breakpoint(entredeux) {
    font-size: 13px;
    line-height: 30px;
  }
}
/*******************************************
********************************************
            STROKED BUTTON
********************************************
********************************************/
button.mat-stroked-button,
a.mat-stroked-button {
  border: 2px solid currentColor;
  border-radius: 0;
  text-transform: uppercase;
  font-family: $font-text;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 600;
  box-shadow: none;
  margin: 0 10px;
  &.mat-primary {
    border-color: var(--color-primary);
    color: var(--color-primary);
    &:hover {
      border-color: var(--color-primary-bis);
      color: var(--color-primary-bis);
      background: transparent;
      text-shadow: 0 0 4px var(--color-primary);
      box-shadow: inset 0 0 4px var(--color-primary), 0 0 4px var(--color-primary);
    }
  }
  &.mat-accent {
    border-color: var(--color-secondary);
    color: var(--color-secondary);
    &:hover {
      background: transparent;
      text-shadow: 0 0 4px var(--color-secondary);
      box-shadow: inset 0 0 4px var(--color-secondary), 0 0 4px var(--color-secondary);
    }
  }
  &.mat-warn {
    border-color: var(--color-danger);
    color: var(--color-danger);
    &:hover {
      background-color: var(--color-danger);
      color: var(--color-white);
    }
  }
  &.mat-button-disabled {
    border-color: var(--color-disabled);
    color: var(--color-disabled) !important;
    &:hover {
      box-shadow: none;
      text-shadow: none;
    }
  }
}
/*******************************************
********************************************
                   MAT FAB
********************************************
********************************************/
button.mat-fab {
  box-shadow: none !important;
  margin: 0 10px;
  .mat-button-wrapper {
    padding: 0;
  }
  .mat-icon {
    color: var(--color-white);
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    width: 33px;
    height: 33px;
  }
  &.transparent {
    background-color: transparent !important;
    &.big-icon {
      .mat-icon {
        font-size: 35px;
      }
    }
  }
  &.mat-primary {
    &:hover {
      background-color: var(--color-primary-900);
    }
  }
  &.mat-accent {
    &:hover {
      background-color: var(--color-secondary-900);
    }
  }
  &.mat-warn {
    &:hover {
      background-color: #df0000;
    }
  }
  &.mat-button-disabled {
    .mat-icon {
      color: #ecceeb !important;
    }
    background-color: var(--color-disabled) !important;
  }

  &.close {
    right: -10px;
    top: 0;
    .mat-button-focus-overlay {
      display: none;
    }
    .mat-button-ripple {
      display: none;
    }
    &:hover {
      background-color: transparent !important;
    }
    .mat-icon {
      color: #fff !important;
      font-size: 15px;
      filter: none;
      &:hover {
        text-shadow: 0 0 4px var(--color-secondary);
      }
    }
  }
}
/*******************************************
********************************************
            MAT MINI FAB - LINEAR
********************************************
********************************************/
button.mat-mini-fab {
  background-color: transparent !important;
  border: 1px solid currentColor;
  margin: 0 10px;
  width: 56px;
  height: 56px;
  .mat-button-wrapper {
    padding: 0;
  }
  &.mat-primary {
    border-color: var(--color-primary-bis);
    color: var(--color-primary-bis);
    box-shadow: inset 0 0 6px var(--color-primary), 0 0 4px 0.5px var(--color-primary),
      0 0 5px 0.5px var(--color-primary);
    text-shadow: 0 0 6px var(--color-primary), 0 0 6px var(--color-primary);
    &:hover {
      background: transparent;
    }
  }
  &.mat-accent {
    border-color: var(--color-secondary);
    color: var(--color-secondary);
    box-shadow: inset 0 0 6px var(--color-secondary), 0 0 4px 0.5px var(--color-secondary),
      0 0 5px 0.5px var(--color-secondary);
    text-shadow: 0 0 6px var(--color-secondary), 0 0 6px var(--color-secondary);
    &:hover {
      background: transparent;
    }
  }
  &.mat-warn {
    border-color: var(--color-danger);
    color: var(--color-danger);
    box-shadow: none;
    &:hover {
      background-color: var(--color-danger) !important;
      color: var(--color-white);
    }
  }
  &.mat-button-disabled {
    border-color: var(--color-disabled);
    color: var(--color-disabled) !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  .mat-icon {
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    width: 33px;
    height: 33px;
  }
  &.transparent {
    border-color: transparent !important;
    box-shadow: none !important;
    &.big-icon {
      .mat-icon {
        font-size: 35px;
      }
    }
  }
}

.carousel-indicators {
  button.mat-mini-fab {
    &.mat-accent {
      border-color: var(--color-disabled);
      background-color: transparent !important;
      box-shadow: none;
      text-shadow: none;
    }
    &.mat-button-disabled {
      border-color: var(--color-secondary);
      background-color: var(--color-secondary) !important;
      box-shadow: inset 0 0 6px var(--color-secondary), 0 0 4px 0.5px var(--color-secondary),
        0 0 5px 0.5px var(--color-secondary) !important;
    }
    &:hover {
      border: 1px solid var(--color-secondary);
      background-color: transparent !important;
    }
  }
}

/*******************************************
********************************************
            LOADER IN BUTTON
********************************************
********************************************/
@mixin loaderBtn() {
  &.is-loading {
    color: transparent !important;

    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid var(--color-white);
      border-top-color: var(--color-primary);
      animation: spinner 0.8s linear infinite;
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

button,
a {
  @include loaderBtn();
}
