mat-tab-group,
.mat-tab-nav-bar {
  margin-top: 30px;
  .mat-tab-header {
    border-bottom: 1px solid var(--color-disabled);
    z-index: 1000;
    overflow: visible;
  }
  .mat-tab-label-container,
  .mat-tab-link-container {
    @include breakpoint(large) {
      overflow: visible;
    }

    .mat-tab-label,
    .mat-tab-link {
      height: 35px;
      color: var(--color-white);
      text-transform: uppercase;
      font-family: var(--font-text);
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 2px;
      opacity: 1;

      &.mat-tab-label-active {
        font-weight: 700;
      }

      // badges used in stats tabs
      .badge-stats {
        margin-left: 8px;
        font-size: 10px;
        border-radius: 50%;
        border: 1px solid var(--color-white);
        width: 22px;
        height: 22px;
        display: inline;
        align-content: center;
        justify-content: space-around;
        padding: 0;
        line-height: 21px;
        letter-spacing: 0;
        @include breakpoint(desktop) {
          font-size: 11px;
          display: inline-flex;
        }
      }

      &.mat-tab-label-active .badge-stats {
        border: 1px solid var(--color-primary-bis);
        box-shadow: 0px 0px 5px 1px var(--color-primary-bis);
      }
    }
  }

  mat-ink-bar.mat-ink-bar {
    background-color: transparent !important;
    bottom: -1px;
    z-index: 1005;
    //display: none;
    //position: relative;

    &:after {
      content: '';
      width: 50px;
      height: 2px;
      position: absolute;
      background-color: var(--color-blue) !important;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      box-shadow: inset 0 0 6px var(--color-blue), 0 0 6px var(--color-blue);
    }
  }

  mat-tab-body {
    padding: 30px 0;
    font-family: var(--font-text);
  }
}
